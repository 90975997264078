import Vue from "vue";
import axios from "@/axios";
import router from "@/routes/router";
import config from "../../config";
import { Message } from "element-ui";
import { apiHandler } from "@/util/errorHandling";
import preloader from "@/util/preloader";
import { cookies, deleteCookie, getCookie, setCookie } from "@/util/cookies";
import setUTMParamsInLocalStorage from "@/util/utmparams";
import {
  setValuesInLocalStorage,
  checkIfKeyExist,
  removeKeyFromLocaleStorage,
} from "@/util/localStorageHandler";
import { get } from "lodash";
import { dispatch } from "d3";

const getDefaultState = () => {
  return {
    hasWorkspace: false,
    user: null,
    userRole: null,
    websiteLanguages: ["en", "it", "de", "es", "fr", "br", "ar"],
    selectedLanguage: "en",
    token: null,
    jobRoles: null,
    isUserImpersonated: false,
    isUserInfoFetched: false,
    walletInfo: null,
    billingInfo: {
      first_name: "",
      last_name: "",
      address: "",
      state: "",
      zip: "",
      city: "",
      fiscal_code: "",
      sdi: "",
      company: "",
      company_pec: "",
      iso2code: "",
    },
    checkUserLimit: null,
    myBalanceInfo: null,
    failedPayments: {},
    getFailedPayments: true,
    getFailedPaymentUrl: "",
    possibleQueryParamsToBeChecked: ["reactivation-code"],
    userHasReactivationCode: false,
  };
};

const moduleUser = {
  state: getDefaultState(),
  getters: {
    getToken(state) {
      return state.token;
    },
    getUser(state) {
      return state.user;
    },
    getHasWorkspace(state) {
      return state.hasWorkspace;
    },
    hasAvatar(state) {
      return Boolean(state.user?.profile_photo_path);
    },
    getAvatar(state) {
      return (
        state.user?.profile_photo_path ??
        process.env.BASE_URL + "img/empty_profile_placeholder.png"
      );
    },
    getJobRoles(state) {
      return state.jobRoles;
    },
    isUserLoggedIn(state) {
      return state.user !== null;
    },
    getUserRole(state) {
      return state.userRole;
    },
    getWebsiteLanguages(state) {
      return state.websiteLanguages;
    },
    getSelectedLanguage(state) {
      return state.selectedLanguage;
    },
    isUserMaster(state, getters) {
      if (getters.getUserRole) {
        return getters.getUserRole === "master";
      }
      return null;
    },
    getIsUserImpersonated(state) {
      return state.isUserImpersonated;
    },
    getIsUserInfoFetched(state) {
      return state.isUserInfoFetched;
    },
    walletInfoObj(state) {
      if (!state.walletInfo) {
        return null;
      }
      setCookie(
        cookies.COOKIE_USER_PLAN,
        state.walletInfo?.user_ai_request.planName,
      );

      return {
        activePlanPackage: state.walletInfo?.activePlanPackage,
        walletAmount: state.walletInfo.wallet_amount / 100,
        isAiCreditsUnlimited: state.walletInfo?.user_ai_request?.isUnlimited,
        aiCredits: state.walletInfo?.user_ai_request?.isUnlimited
          ? "unlimited"
          : state.walletInfo?.user_ai_request?.remainingRequest,
        extraCredits: state.walletInfo?.extraCredits,
        currencySymbol: state.walletInfo?.currency_symbol,
        planName: state.walletInfo?.user_ai_request.planName,
        userIsFTFlow: state.walletInfo?.userIsFTFlow,
        user_master_email: state.walletInfo?.user_master_email,
        yearlySub: state.walletInfo?.user_active_plans?.yearly || 0,
        userSeats: state.walletInfo?.userExtraSeats,
        userActivePlans: state.walletInfo?.user_active_plans,
        subscriptionEndDate: state.walletInfo?.subscriptionExpectedEndDate,
        isDemoUser: state.walletInfo.is_demo_user,
      };
    },
    getBillingInfo(state) {
      return state.billingInfo;
    },
    getCheckUserLimit(state) {
      return state.checkUserLimit;
    },
    getMyBalanceInfo(state) {
      return state.myBalanceInfo;
    },
    isFailedPaymentsProcessed(state) {
      return state.getFailedPayments;
    },
    getFailedPaymentUrl(state) {
      return state.failedPayments?.latest_invoice?.hosted_invoice_url;
    },
    getPossibleQueryParamsToBeChecked(state) {
      return state.possibleQueryParamsToBeChecked;
    },
    getUserHasReactivationCode(state) {
      return state.userHasReactivationCode;
    },
  },
  mutations: {
    clearModuleUser(state) {
      Object.assign(state, getDefaultState());
    },
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setHasWorkspace(state, hasWorkspace) {
      state.hasWorkspace = hasWorkspace;
    },
    setJobRoles(state, jobRoles) {
      state.jobRoles = jobRoles;
    },
    setUserRole(state, userRole) {
      state.userRole = userRole;
    },
    setSelectedLanguage(state, selectedLanguage) {
      state.selectedLanguage = selectedLanguage;
    },
    clearAuthCookies() {
      deleteCookie(cookies.COOKIE_TOKEN_KEY);
      deleteCookie(cookies.COOKIE_USER_KEY);
      deleteCookie(cookies.COOKIE_IMPERSONATE_USER_KEY);
      deleteCookie(cookies.COOKIE_IMPERSONATE_TOKEN_KEY);
    },
    setIsUserInfoFetched(state, isUserInfoFetched) {
      console.log("setIsUserInfoFetched", isUserInfoFetched);
      state.isUserInfoFetched = isUserInfoFetched;
    },
    clearLanguageCookies() {
      deleteCookie(cookies.COOKIE_SELECTED_LANGUAGE);
    },
    clearWorkspaceCookies() {
      deleteCookie(cookies.COOKIE_WORKSPACE);
    },
    updateWallet(state, wallet) {
      state.walletInfo = wallet;
    },
    setBillingInfo(state, info) {
      state.billingInfo = info;
    },
    setcheckUserLimit(state, info) {
      state.checkUserLimit = info;
    },
    setMyBalanceInfo(state, info) {
      state.myBalanceInfo = info;
    },
    setUserFailedPayments(state, data) {
      state.failedPayments = data.data;
      state.getFailedPayments = data.isFailedPaymentsProcessed;
    },
    setUserHasReactivationCode(state, data) {
      state.userHasReactivationCode = data;
    },
  },
  actions: {
    /**
     * Fetch the user from session or local storage and update Vuex store if available.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.state - Vuex state object.
     * @param {Object} context.commit - Vuex commit function.
     */
    fetchUser({ state, commit }) {
      // Check if the user is already in the state
      if (state.user) {
        return;
      }

      // Get impersonated user (if existing)
      let user = getCookie(cookies.COOKIE_IMPERSONATE_USER_KEY);
      state.isUserImpersonated = true;

      if (!user) {
        user = getCookie(cookies.COOKIE_USER_KEY);
        state.isUserImpersonated = false;
      }

      if (user) {
        commit("setUser", user);
      }
    },

    /**
     * Fetch the bearer token from cookies and update Vuex store if available.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.state - Vuex state object.
     * @param {Object} context.commit - Vuex commit function.
     */
    fetchToken({ state, commit }) {
      // Check if the token is already in the state
      if (state.token) {
        return;
      }

      // Get impersonated user token (if existing)
      let token = getCookie(cookies.COOKIE_IMPERSONATE_TOKEN_KEY);
      state.isUserImpersonated = true;

      if (!token) {
        token = getCookie(cookies.COOKIE_TOKEN_KEY);
        state.isUserImpersonated = false;
      }

      if (token) {
        commit("setToken", token);
      }
    },

    /**
     * Fetch user information from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchUserInfo({ getters, commit }) {
      let user;
      user = getters.getUser;
      const response = await axios.get("tools/show_user_info", {
        params: {
          user_id: user?.id ? user.id : user,
        },
      });

      // Update Vuex store with user role and workspace information
      commit("setUser", response.data.user_info);
      commit("setJobRoles", response.data.job_roles);
      commit("setUserRole", response.data.user_info.role);
      commit("setHasWorkspace", response.data.user_info.workspace);
      commit("setTools", response.data.templates);
      commit("setToolsCategories", response.data.categories);
      commit("setIsUserInfoFetched", true);
      user = getters.getUser;
      let userIdentifiedData = {
        user_id: user.id,
        email: user.email,
        full_name: user.name,
        signup_utm_source: user.signup_utm_source,
        signup_utm_medium: user.signup_utm_medium,
        signup_utm_campaign: user.signup_utm_campaign,
        signup_utm_content: localStorage.getItem("utm_content"),
        country: user.country,
        customer_type: user.customer_type,
        industry: user.job_role,
      };
      window.gtm.user_identified(userIdentifiedData);
    },

    fetchSelectedLanguage({ commit }) {
      let selectedLanguage = getCookie(cookies.COOKIE_SELECTED_LANGUAGE);

      if (!selectedLanguage) {
        selectedLanguage = "en";
      }

      commit("setSelectedLanguage", selectedLanguage);
    },

    /**
     * @param {Object} params
     * @param {string} params.selectedLanguage
     * @param {Object} params.i18n - i18n instance
     */
    updateSelectedLanguage({ state, commit }, params) {
      if (!state.websiteLanguages.includes(params.selectedLanguage)) {
        new Error(`The language ${params.selectedLanguage} is not allowed.`);
      }

      setCookie(cookies.COOKIE_SELECTED_LANGUAGE, params.selectedLanguage);
      commit("setSelectedLanguage", params.selectedLanguage);
      params.i18n.locale = params.selectedLanguage;

      const isRTL = params.selectedLanguage === "ar";
      const documentElement = document.documentElement.classList;
      return isRTL ? documentElement.add("rtl") : documentElement.remove("rtl");
    },

    updateSelectedLanguageOnMounted({ dispatch }, params) {
      const isRTL = getCookie(cookies.COOKIE_SELECTED_LANGUAGE) === "ar";
      if (isRTL) {
        params.selectedLanguage = "ar";
      } else {
        let selectedLanguage = getCookie(cookies.COOKIE_SELECTED_LANGUAGE);
        if (getCookie(cookies.COOKIE_ISITUTIONAL_SITE_LANG)) {
          selectedLanguage = getCookie(cookies.COOKIE_ISITUTIONAL_SITE_LANG);
          deleteCookie(cookies.COOKIE_ISITUTIONAL_SITE_LANG);
        }
        params.selectedLanguage = selectedLanguage;
      }

      dispatch("updateSelectedLanguage", params);
    },

    /**
     * Perform user login by sending credentials to the authentication service.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.commit - Vuex commit function.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} params - Login parameters including email, password, and remember_me.
     * @param {string} params.email - User email for login.
     * @param {string} params.password - User password for login.
     * @param {boolean} params.remember_me - Flag indicating whether to remember the user.
     * @param {boolean} params.fastCheckout - Flag indicating whether we are logging in from fast checkout flow.
     */
    async login({ commit, dispatch, getters }, params) {
      await apiHandler(async () => {
        const response = await axios.post("services/auth/login", {
          email: params.email,
          password: params.password,
        });

        setUTMParamsInLocalStorage();

        setValuesInLocalStorage(getters.getPossibleQueryParamsToBeChecked);

        const user = { id: response.data.user.id };
        const token = response.data["service-api"];

        if (params.remember_me) {
          setCookie(cookies.COOKIE_TOKEN_KEY, token, 24 * 365);
          setCookie(cookies.COOKIE_USER_KEY, user, 24 * 365);
        } else {
          setCookie(cookies.COOKIE_TOKEN_KEY, token);
          setCookie(cookies.COOKIE_USER_KEY, user);
        }

        // Fetch user and token information
        await dispatch("fetchUser");
        await dispatch("fetchToken");
        console.log(response.data, "aooo");
        window.gtm.login(response.data.user ?? []);

        router.push("/");

        return response;
      });
    },
    async fastLogin({ commit, dispatch, getters }, params) {
      await apiHandler(async () => {
        const response = await axios.post("services/auth/login", {
          email: params.email,
          password: params.password,
        });

        window.gtm.login(response.data.user ?? []);
        setUTMParamsInLocalStorage();

        const user = { id: response.data.user.id };
        const token = response.data["service-api"];

        if (params.remember_me) {
          setCookie(cookies.COOKIE_TOKEN_KEY, token, 24 * 365);
          setCookie(cookies.COOKIE_USER_KEY, user, 24 * 365);
        } else {
          setCookie(cookies.COOKIE_TOKEN_KEY, token);
          setCookie(cookies.COOKIE_USER_KEY, user);
        }

        // Fetch user and token information
        await dispatch("fetchUser");
        await dispatch("fetchToken");

        // Redirect to the home page after successful login
        if (params.freeTrial || params.fastCheckout) {
          /* await dispatch("fetchUserInfo");
          await dispatch("getBillingInfo");
          await dispatch('payments/fetchDefaultPaymentMethod') */
          await Promise.all([
            dispatch("fetchUserInfo"),
            dispatch("getBillingInfo"),
            dispatch("payments/fetchDefaultPaymentMethod"),
          ]);
          if (params.freeTrial) {
            commit("fastcheckout/setStep", getters["fastcheckout/step"] + 1);
          }
        } else {
          router.push("/");
        }

        return response;
      });
    },
    async fastRegister({ commit, dispatch, getters }, params) {
      await apiHandler(async () => {
        const response = await axios.post(
          `/subscribe-now/register-no-freetrial${window.location.search}`,
          {
            ...params,
          },
        );
        const user = response.data.data.user;
        window.gtm.sign_up(response.data.user);
        setUTMParamsInLocalStorage();

        commit("setUser", user);
        const intent = response.data.data.intent;

        const token = response.data.data["service-api"];
        setCookie(cookies.COOKIE_TOKEN_KEY, token);
        setCookie(cookies.COOKIE_USER_KEY, user);

        dispatch("fetchUser");
        dispatch("fetchToken");
        commit("payments/setStripeClientSecret", intent.client_secret);
        return response;
      });
    },
    async freeTrialRegisterUser({ commit, dispatch, getters }, params) {
      await apiHandler(async () => {
        const response = await axios.post(
          `features/fastcheckout/subscribe-now${window.location.search}`,
          { ...params },
        );
        const user = response.data.data.user;
        window.gtm.sign_up(response.data.user);
        setUTMParamsInLocalStorage();

        commit("setUser", user);
        const intent = response.data.data.intent;
        const token = response.data["service-api"];
        setCookie(cookies.COOKIE_TOKEN_KEY, token);
        setCookie(cookies.COOKIE_USER_KEY, user);
        dispatch("fetchUser");
        dispatch("fetchToken");
        commit("payments/setStripeClientSecret", intent.client_secret);
        commit("fastcheckout/setStep", getters["fastcheckout/step"] + 1);

        return response;
      });
    },
    async socialLogin({ commit, getters, dispatch }, params) {
      preloader.fadeIn();

      try {
        const res = await axios.post("services/auth/social_login", {
          driver: params.driver,
          credential: params.credential,
          freetrial: params.freeTrial,
        });

        window.gtm.login(res.data.user ?? []);
        setUTMParamsInLocalStorage();
        console.log(res);
        if (res && res.status === 200) {
          const { data } = res;
          const user = { id: data.user.id };
          const token = data["service-api"];
          commit("setToken", token);

          setCookie(cookies.COOKIE_TOKEN_KEY, token);
          setCookie(cookies.COOKIE_USER_KEY, user);

          // Fetch user and token information
          //@todo check if these are necessary and if they can be parallelized
          await dispatch("fetchUser");
          await dispatch("fetchToken");
          await dispatch("fetchUserInfo");

          preloader.fadeOut();

          if (data.must_complete_profile) {
            if (!params.freeTrial) {
              router.push("/register");
            } else {
              if (params.freeTrial) {
                commit(
                  "fastcheckout/setStep",
                  getters["fastcheckout/step"] + 1,
                );
              }
            }
          } else {
            if (!params.freeTrial && !params.fastCheckout) {
              router.push("/");
            } else {
              console.log("----------");
              await dispatch("getBillingInfo");
              if (params.freeTrial) {
                commit(
                  "fastcheckout/setStep",
                  getters["fastcheckout/step"] + 1,
                );
              }
            }
            // Redirect to the home page after successful login
          }

          return res;
        } else {
          throw Error;
        }
      } catch (error) {
        preloader.fadeOut();

        let social =
          params.driver.charAt(0).toUpperCase() + params.driver.slice(1) ??
          "the Social Login";
        Message({
          showClose: true,
          message:
            "Unable to log in to " +
            social +
            ". Make sure you have a profile with a public email",
          type: "error",
        });
      }
    },

    /**
     * Request to reset user password and send a reset link to the provided email.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for requesting password reset.
     * @param {string} params.email - User email for password reset.
     */
    async rememberPassword(context, params) {
      await apiHandler(async () => {
        const response = await axios.post(
          "services/auth/remember_password",
          params,
        );

        return response;
      });
    },

    /**
     * Log out the user by clearing cookies, and resetting the Vuex store state.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.commit - Vuex commit function.
     */
    async logout({ commit, dispatch }) {
      deleteCookie(cookies.COOKIE_USER_PLAN);
      // Clear auth cookies
      commit("clearAuthCookies");
      commit("clearLanguageCookies");
      commit("clearWorkspaceCookies");

      // Redirect to the login page after logout (and empty the store's state)
      window.location.replace("/login");
    },
    /**
     *
     * @param {Object} context.commit - Vuex commit function.
     */
    async fastLogout({ commit }) {
      deleteCookie(cookies.COOKIE_USER_PLAN);
      commit("clearAuthCookies");
      commit("clearLanguageCookies");
      commit("clearWorkspaceCookies");
      window.location.reload();
    },
    /**
     * Update user profile
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for update profile.
     * @param {string} params.name - User name
     * @param {string} params.job_role_id - User job id
     */
    async updateProfile(context, params) {
      await apiHandler(async () => {
        const response = await axios.post("tools/update_user_info", params);
        return response;
      });
    },

    /**
     * Change password
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for change password.
     * @param {string} params.current_password - Current password
     * @param {string} params.new_password - New password
     * @param {string} params.new_confirm_password - New confirm password
     */
    async changePassword(context, params) {
      await axios
        .post("services/auth/change_password", params)
        .then((response) => {
          Message({
            showClose: true,
            message: response.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
          Message({
            showClose: true,
            message:
              typeof error.response?.data?.message?.current_password !=
              "undefined"
                ? error.response?.data?.message?.current_password[0]
                : typeof error.response?.data?.message?.new_password !=
                  "undefined"
                ? error.response?.data?.message?.new_password[0]
                : typeof error.response?.data?.message?.new_confirm_password !=
                  "undefined"
                ? error.response?.data?.message?.new_confirm_password[0]
                : typeof error.response?.data?.message != "undefined"
                ? error.response?.data?.message
                : error.message,
            type: "error",
          });
          return error;
        });
    },

    /**
     * Reset password
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for reset password.
     * @param {String} token - Token for reset password.
     * @param {string} params.password - New password
     * @param {string} params.password_confirmation - New confirm password
     */
    async resetPassword(context, params) {
      const response = await axios
        .post("services/auth/reset_password", { ...params })
        .then((response) => {
          Message({
            showClose: true,
            message: "Your password has been reset",
            type: "success",
          });
          setTimeout(() => {
            window.location.replace("/login");
          }, 1000);
        })
        .catch((error) => {
          Message({
            showClose: true,
            message:
              typeof error.response?.data?.message?.password != "undefined"
                ? error.response?.data?.message?.password[0]
                : typeof error.response?.data?.message?.password_confirmation !=
                  "undefined"
                ? error.response?.data?.message?.password_confirmation[0]
                : typeof error.response?.data?.message != "undefined"
                ? error.response?.data?.message
                : error.message,
            type: "error",
          });
        });
      return response;
    },

    /**
     * @async
     * @param {Object} context - The Vuex context object.
     * @param {Object} params
     * @param {string} params.userId
     * @param {string} params.token
     * @throws {Error} If the parameters are not valid.
     */
    async impersonateUser(context, params) {
      // params validation
      if (typeof params !== "object" || params === null) {
        throw new Error(`The given parameters are not valid.`);
      }
      // userId validation
      if (
        typeof parseInt(params?.userId) !== "number" ||
        isNaN(params?.userId)
      ) {
        throw new Error(`The given userId is not valid.`);
      }
      // token validation
      if (typeof params?.token !== "string" || params?.token === null) {
        throw new Error(`The given token is not valid.`);
      }

      const user = {
        id: params?.userId,
      };
      const domain = config.cookieDomain;

      // Set session cookies for the impersonated user
      document.cookie = `${
        cookies.COOKIE_IMPERSONATE_USER_KEY
      }=${JSON.stringify(user)}; domain=${domain}; path=/`;
      document.cookie = `${
        cookies.COOKIE_IMPERSONATE_TOKEN_KEY
      }=${JSON.stringify(params.token)}; domain=${domain}; path=/`;

      window.location.replace("/");
    },

    leaveImpersonate() {
      deleteCookie(cookies.COOKIE_IMPERSONATE_USER_KEY);
      deleteCookie(cookies.COOKIE_IMPERSONATE_TOKEN_KEY);
      window.location.replace("/");
    },
    async updateToken({ commit }) {
      const response = await axios.post("tools/update_api_token");

      if (response && response.status === 200) {
        const token = response.data.plainTextToken;

        if (token) {
          setCookie(cookies.COOKIE_TOKEN_KEY, token);
          commit("setToken", token);
          Vue.prototype.$message({
            message: "Token Refreshed successfully",
            type: "success",
          });
        }
      } else {
        Vue.prototype.$message({
          message: "error updating token",
          type: "error",
        });
      }
    },
    async fetchWalletInfo({ commit }) {
      try {
        const response = await axios.get(`tools/show_user_wallet`);
        commit("updateWallet", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async deactivateAccount({ getters }) {
      const user = getters.getUser;
      const response = await axios.delete("/services/auth/deactivate_account", {
        params: {
          user_id: user.id,
        },
      });
      return response;
    },

    async verifyMail(context, params) {
      const response = await axios
        .post("services/auth/verify", { ...params })
        .then((response) => {
          preloader.fadeOut();
          Message({
            showClose: true,
            message: response.data.message,
            type: "success",
          });
          setTimeout(() => {
            window.location.replace("/login");
          }, 4000);
        })
        .catch((error) => {
          Message({
            showClose: true,
            message: "Invalid data",
            type: "error",
          });
        });
      return response;
    },

    async getParamsRegistration() {
      const response = await axios.get("services/auth/get_register_params");
      return response;
    },

    async getBillingInfo({ commit, getters }) {
      await apiHandler(async () => {
        const user = getters.getUser;

        const userId = user.id ? user.id : parseInt(user);
        const response = await axios.get(
          "/profile/billinginfo/get_billing_info",
          {
            params: {
              user_id: userId,
            },
          },
        );
        const data = response.data.data;
        commit("setBillingInfo", data);
        return response;
      }, false);
    },

    async updateBillingInfo({ getters }, params) {
      const user = getters.getUser;

      params.user_id = user.id;
      return await axios.post("/profile/billinginfo/update_billing_info", {
        ...params,
      });
    },
    async register({ commit, dispatch, getters }, params) {
      await apiHandler(async () => {
        const response = await axios.post("services/auth/register", {
          ...params,
        });

        window.gtm.sign_up(response.data.user);
        setUTMParamsInLocalStorage();

        router.push("/login");

        return response;
      });
    },

    async getRedemedCouponList({ getters }, params) {
      const user = getters.getUser;
      const response = await axios.get(
        "/profile/balance/get_redeemed_coupons",
        {
          params: {
            user_id: user.id,
            date_from: params.date_from,
            date_to: params.date_to,
          },
        },
      );
      return response;
    },

    async getInvoiceList({ getters }, params) {
      const user = getters.getUser;
      const response = await axios.get("/profile/balance/get_invoice_list", {
        params: {
          user_id: user.id,
          date_from: params.date_from,
          date_to: params.date_to,
        },
      });
      return response;
    },

    async getBusinessCentralInvoiceList({ getters }, params) {
      const customerNumber = getters.getBillingInfo;
      const user = getters.getUser;
      const response = await axios.get(
        "/profile/balance/get_business_central_invoice_list",
        {
          params: {
            user_id: user.id,
            customer_number:
              customerNumber.billing_information.business_central_guid,
          },
        },
      );
      return response;
    },

    async getMyBalanceInfo({ getters, commit }) {
      const user = getters.getUser;
      const response = await axios.get("/profile/balance/get_balance_panel", {
        params: {
          user_id: user.id,
        },
      });
      commit("setMyBalanceInfo", response.data);

      return response;
    },

    async checkUserLimits({ commit, getters }) {
      const user = getters.getUser;
      const response = await axios.get("/plans/check-user-limits", {
        params: {
          user_id: user.id,
        },
      });
      commit("setcheckUserLimit", response.data.response);
      return response;
    },

    async removeExtraLimits({ getters }, params) {
      const user = getters.getUser;
      params.user_id = user.id;
      const response = await axios.post("/plans/remove-extra-limits", {
        ...params,
      });
      return response;
    },

    async downloadInvoice({ getters }, { id, hash }) {
      await apiHandler(async () => {
        const user = getters.getUser;

        const queryParams = {
          user_id: user.id,
          invoice_id: id,
          hash: hash,
        };

        const response = await axios.get("/profile/balance/download-invoice", {
          params: queryParams,
          responseType: "blob",
        });
        console.log(response.request);
        let filename = response.request
          .getResponseHeader("Content-Disposition")
          .split("filename=")[1];

        const blob = new Blob([response.data]);
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        link.remove();

        return response;
      });
    },

    async checkFailedPayments(context) {
      await axios
        .get(`payments/get-user-failed-payments`, {
          headers: { Authorization: `Bearer ${context.state.apiToken}` },
        })
        .then((response) => {
          context.commit("setUserFailedPayments", response.data);
        })
        .catch((error) => console.error(error));
    },

    redirectToWebsitePricingPage() {
      window.location.replace(process.env.VUE_APP_WEBSITE_PRICING_PAGE);
    },

    checkForSubscriptionReactivationCode(context) {
      if (checkIfKeyExist("reactivation-code")) {
        context.commit("setUserHasReactivationCode", true);
      }
    },

    deleteReactivationCode(context) {
      context.commit("setUserHasReactivationCode", false);
      removeKeyFromLocaleStorage("reactivation-code");
    },
  },
};

export default moduleUser;
