/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.4.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboardPlugin.js";
import AxiosPlugin from "./plugins/axiosPlugin.js";
import PreloaderPlugin from "./plugins/preloaderPlugin.js";
import ComprehendPlugin from "./plugins/comprehendPlugin.js";
import App from "./App.vue";
import store from "./store/store";
import "es6-promise/auto";
import Vue2Editor from "vue2-editor";
import { Message, Image } from "element-ui";
import { googleTagManager } from "./util/gtm.js";
import VueAgile from "vue-agile";
import config from "./config";

// Router setup
import router from "./routes/router";
import i18n from "./i18n";

// if (window.location.href.includes("https://app.contents.ai/")) {
//   console.log = function () {};
// }

//Remove service worker
navigator.serviceWorker.getRegistrations().then((registrations) => {
  for (const registration of registrations) {
    registration.unregister();
  }
});

window.gtm = googleTagManager;
window.stripePublicKey = config.stripePublicKey;
window.googleRecaptchaKey = config.googleRecaptchaKey;
window.isGoogleRecaptchaActive = config.isGoogleRecaptchaActive;
window.aiArtUrl = config.aiArtUrl;
window.dataLayer = window.dataLayer || [];

// Fetch user data, authentication token needed by the router
store.dispatch("fetchUser");
store.dispatch("fetchToken");
store.dispatch("fetchSelectedLanguage");

// Plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(AxiosPlugin);
Vue.use(PreloaderPlugin);
Vue.use(ComprehendPlugin);
Vue.use(Vue2Editor);
Vue.use(Image);
Vue.prototype.$message = Message;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  i18n,
  store,
  Message,
  // emitter,
});
