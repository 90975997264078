<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import config from "./config";
import { mapGetters } from "vuex";
import { Userpilot } from 'userpilot'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log('beforerouteenter', vm.$store.getters.isUserLoggedIn)
    })
  },
  computed: {
    ...mapGetters({
      isUserInfoFetched: "getIsUserInfoFetched",
      selectedLanguage: "getSelectedLanguage",
      user: "getUser",
    }),
  },
  watch: {
    isUserInfoFetched(newValue, oldValue) {
      if (newValue) {
        this.initUserPilot();
      }
    }
  },
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
    initUserPilot() {
      if (this.user && this.user.id && !this.user.show_survey_modal) {
        console.log("initUserPilot");
        Userpilot.initialize(config.userPilotId);

        Userpilot.identify(this.user.id.toString(), {
          "name": this.user.name,
          "email": this.user.email,
          "lang": this.selectedLanguage
        });
      }
    }
  },
  async mounted() {
    this.initializeLayout();
  }
};
</script>
