<template>
  <el-dialog
    :visible.sync="showModal"
    width="35%"
    center
    :lock-scroll="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="el-dialog-payout"
  >
    <h4
      v-html="
        myProfile
          ? $t('auth.reactivate_subscription_modal.confirm_desc_my_profile')
          : $t('auth.reactivate_subscription_modal.confirm_desc')
      "
      class="text-center mb-0"
    ></h4>
    <span slot="footer" class="dialog-footer">
      <div class="row mb-2">
        <div class="col-sm-12 text-center">
          <BaseButton
            class="sub-btn cancel-sub-btn mr-2"
            @click="myProfile ? closeModal() : cancelSubscriptionReactivation"
            v-text="$t('auth.reactivate_subscription_modal.cancel_btn')"
          />
          <BaseButton
            class="sub-btn"
            @click="reactivateMainSubscription"
            v-text="$t('auth.reactivate_subscription_modal.confirm_btn')"
          />
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { Dialog } from "element-ui";
import BaseButton from "../../../components/BaseButton.vue";
export default {
  name: "ReactivateSubscriptionModal",
  components: {
    [Dialog.name]: Dialog,
    BaseButton,
  },
  props: ["showModal", "myProfile"],
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    ...mapActions({
      reactivateMainSubscription: "payments/reactivateMainSubscription",
    }),

    async cancelSubscriptionReactivation() {
      this.$preloader.fadeIn();
      await this.$store.dispatch("deleteReactivationCode");
      this.$preloader.fadeOut();
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-sub-btn {
  background: linear-gradient(203deg, #eb9634 0%, #eb4034 100%) !important;
}
.el-dialog__wrapper:after {
  background-color: transparent !important;
  content: "" !important;
}
.sub-btn {
  width: 45% !important;
}
</style>
